import React from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

import * as styles from '../../styles/modules/home/text.module.css'

const Text = ({ text }) => {
  let htmlText = documentToHtmlString(JSON.parse(text.content.raw))

  return (
    <div className={styles.textContainer}>
      <div className={styles.title}>
        <span className={styles.blueText}>{text.blueText}</span>
        <span className="text-primary">{text.blackText}</span>
      </div>
      <div
        className={styles.textContent}
        dangerouslySetInnerHTML={{
          __html: htmlText,
        }}
      ></div>
    </div>
  )
}

export default Text
